import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react'

const Styles = styled.div`

	.container {
        display: inline-block;
	}
	
	.content {
		margin-top: 3.125rem;
		padding: 0 1.25rem;
	}

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		margin: 0 0 1rem;
	}

	a {
		color: black;
	}

	ul, ol {
		padding-inline-start: 40px;
	}

	@media only screen and (min-width: 1024px) {

        .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
		}

		.content {
			padding: 0;
		}
	}

`;

export default function TermsPage({data}) {

	var portableTextContent = data.terms._rawCopy;

	return (
		<Styles>
			<div className="pad" >
				<section className="terms-page static">
					<div className="container">
					<div className="col-1 hidden-m"></div>
						<div className="col-10">
							<div className="content">
								<BlockContent 
									blocks={portableTextContent}
								/>
							</div>
						</div>
					</div>
				</section>
			</div>
		</Styles>
	);
}

export const query = graphql`
	query {
		settings: sanitySiteSettings(_id: {eq: "LCsiteSettings"})  {
			description
			id
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 400, width: 400) {
					base64
					srcWebp
					srcSetWebp
				}
				}
			}
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
		}
		terms: sanityTerms(_id: {eq: "LCterms"}) {
			_rawCopy
			titleSEO
			descriptionSEO
			keywordsSEO
		}
		regions: allSanityLCregionPage {
			nodes {
				slug {
					current
				}
				name
				showInNav
			}
		}
		themes: allSanityLCtheme {
			nodes {
			name
			slug {
				current
			}
			showInNav
			}
		}
	}
`
